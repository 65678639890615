import {createContext, useContext, useMemo} from 'react';

import {afterGet} from '@/controllers/countries';
import {getCountries} from '@/graphql/queries';
import {useQuery} from '@apollo/client';

import {ERROR_LEVEL} from '@/constants';
import {tracking} from '@/services/tracking/TrackingService';
import {getCountriesPhoneCodeList} from '@/utils/countries';

import type {GetCountriesQuery, GetCountriesQueryVariables} from '@/graphql/__generated__/graphql';
import type {Countries, CountryListItem} from '@/types/business';
import type {ReactNode} from 'react';

import {useUser} from './UserContext';

type CountriesInfoContextType = {
  countriesInfo: Countries;
  phoneCodeList: CountryListItem[];
  loadingCountriesData: boolean;
};

const CountriesInfoContext = createContext<CountriesInfoContextType>({
  countriesInfo: {},
  phoneCodeList: [],
  loadingCountriesData: true,
});
export const useCountriesInfoContext = () => useContext(CountriesInfoContext);

const CountriesInfoContextProvider = ({children}: {children: ReactNode}) => {
  const {fbUser} = useUser();
  const maxAttemps: number = 2;
  let attemps: number = 0;

  const {
    data: countriesData,
    loading,
    refetch,
  } = useQuery<GetCountriesQuery, GetCountriesQueryVariables>(getCountries, {
    fetchPolicy: 'cache-first',
    skip: !fbUser,
    notifyOnNetworkStatusChange: true,

    onError(error) {
      tracking.logError({
        error_level: ERROR_LEVEL.ALERT,
        error_message: error.message || JSON.stringify(error),
        error_message_id: 'countries/kasta/unable-to-load-counties-data',
      });

      attemps++;
      if (attemps < maxAttemps) refetch();
    },
  });

  const countriesInfo = afterGet(countriesData);
  const countriesLength = Object.keys(countriesInfo).length;
  const phoneCodeList = useMemo(() => getCountriesPhoneCodeList(countriesInfo), [countriesInfo]);

  // TODO: Decide syntax of available countries to be used in signup
  // const ISOCodeAvailableCountries = appConfig.availableCountries?.split(';') || [];

  const loadingCountriesData = loading && countriesLength === 0;
  return (
    <CountriesInfoContext.Provider
      value={{
        countriesInfo,
        phoneCodeList,
        loadingCountriesData,
      }}>
      {children}
    </CountriesInfoContext.Provider>
  );
};

export default CountriesInfoContextProvider;
