import {useCallback, useEffect, useState} from 'react';

import {Button, Icon} from '@kasta-io/components';

import {ERROR_LEVEL, THREE_SECOND} from '@/constants';
import useIsTargetMedia from '@/hooks/useIsTargetMedia';
import {zendeskService} from '@/services/support/ZendeskService';
import {tracking} from '@/services/tracking/TrackingService';
import {handleContactSupport} from '@/utils/support';

import type {ButtonProps} from '@kasta-io/components';

import s from './ZenDeskChatButton.module.scss';

declare global {
  interface Window {
    zE: any;
  }
}

export default function ZenDeskChatButton(props: ButtonProps) {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<string | null>(null);
  const isMobileDevice = useIsTargetMedia('tablet', 'less');

  function messageCountCallback(count: number) {
    if (count > 0) {
      setUnreadMessagesCount(count > 99 ? '99+' : `${count}`);
    }
  }

  const getUnreadMessagesCount = useCallback(() => {
    try {
      zendeskService.getUnreadMessagesCount(messageCountCallback);
    } catch (error: any) {
      tracking.logError({
        error_message: error.message ? `${error.message}` : JSON.stringify(error),
        error_level: ERROR_LEVEL.NOTICE,
        error_message_id: 'zendesk/kasta/unable-to-get-unread-message-count',
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getUnreadMessagesCount();
    }, THREE_SECOND);
  }, [getUnreadMessagesCount]);

  const handleOnClick = useCallback(() => {
    setUnreadMessagesCount(null);
    handleContactSupport();
  }, []);

  return (
    <div role="zendesk-chat-button">
      <Button aria-label="zendesk-chat-button" onClick={handleOnClick} {...props}>
        <Icon name="chat" size={isMobileDevice ? 24 : 28} />

        {unreadMessagesCount && <span className={s.countBadge}>{unreadMessagesCount}</span>}
      </Button>
    </div>
  );
}
